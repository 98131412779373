<template>
  <aside class="list-card-add__comprado">
    <div class="list-card-add__comprado-add" v-if="!alreadyBought">
      <label for="comprado" @click.stop="openAlreadyBought">
        <input type="checkbox" :id="'comprado' + product.id" :key="product.id" />
        <span>{{ $t("generic.setAsPurchased") }}</span>
      </label>

      <!-- ALREADY BOUGHT MODAL -->
      <transition name="bbFade" @after-enter="showAlreadyBoughtContent = true">
        <div
          class="modal"
          v-if="showAlreadyBought"
          @click.stop="showAlreadyBoughtContent = false"
        >
          <transition name="bbUp" @after-leave="closeAlreadyBought()">
            <div v-show="showAlreadyBoughtContent" class="modal__card" @click.stop="">
              <div class="modal__dialog">
                <div class="modal__header">
                  <div class="modal__back"></div>
                  <div class="modal__close">
                    <img
                      src="../../../assets/img/ico/ico-close.svg"
                      alt="Cerrar"
                      @click.stop="showAlreadyBoughtContent = false"
                    />
                  </div>
                </div>
                <div class="modal__content">
                  <div class="flux-regalo">
                    <div class="flux-regalo__title">{{ $t("guest.doYouPurchased") }}</div>

                    <div class="flux-regalo__msg">
                      {{ $t("guest.setAsPurchasedOnlyIf") }}
                    </div>
                    <div class="flux-regalo__msg flux-regalo__msg--small">
                      <img src="../../../assets/img/ico/ico-question.svg" alt="" />
                      {{ $t("guest.setAsPurchasedAdv", { name: myList.ownerName }) }}
                    </div>

                    <div class="flux-regalo__actions">
                      <loading-button v-if="loadingMarcarComoRegalado" />
                      <a
                        v-else
                        href="#"
                        class="button button--primary"
                        @click.stop="marcarComoRegalado()"
                        >{{ $t("guest.setAsPurchasedButton") }}</a
                      >
                    </div>
                    <div class="flux-regalo__informacion">
                      <div
                        class="info-compra"
                        v-if="
                          typeof myList.address === 'string' &&
                          myList.address.trim().length > 0
                        "
                      >
                        <div class="info-compra__title">{{ $t("guest.infoForPurchasing") }}</div>
                        <div class="info-compra__cols">
                          <div class="info-compra__col">
                            <h3 class="info-compra__col-title">
                              {{ $t("guest.addressOf", { name: myList.ownerName })}}
                            </h3>
                            <div class="info-compra__col-content">
                              <list-owner-address :list="list"></list-owner-address>
                            </div>
                          </div>
                          <div class="info-compra__col"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </transition>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingButton from "../../../components/LoadingButton";
import MarcarComoRegalado from "../components/mixins/MarcarComoRegalado";
import ListOwnerAddress from "../components/ListOwnerAddress";

export default {
  name: "AlreadyBought",
  mixins: [MarcarComoRegalado],
  components: {
    LoadingButton,
    ListOwnerAddress,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      myList: "myList",
    }),
    list() {
      return this.myList;
    },
  },
  data() {
    return {
      alreadyBought: false,
      SoldByHelloBB: "No",
      showAlreadyBought: false,
      showAlreadyBoughtContent: false,
    };
  },
  methods: {
    closeAlreadyBought() {
      console.log("closeAlreadyBought");
      this.showAlreadyBought = false;
      this.alreadyBought = false;
      document.body.classList.remove("modal-open");
      this.$emit("close");
    },
    openAlreadyBought() {
      this.showAlreadyBought = true;
      document.body.classList.add("modal-open");
    },
  },
  beforeMount() {
    const escapeHandler = (e) => {
      if (e.key === "Escape") {
        //this.$emit("close");
        this.showAlreadyBoughtContent = false;
      }
    };
    document.addEventListener("keydown", escapeHandler);
    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", escapeHandler);
    });
  },
};
</script>
